<template>
<v-sheet>
  <v-divider :style="`border-color : ${wsBACKGROUND}`" />
  <div class="px-6">

    <page-section background-color="white" no-padding>
      <v-row class="mt-8 pb-16">
        <v-col cols="12" md="2">
          <router-link :to="localeLink('')">
            <img class="text-center"
                 height="32"
                 width="auto"
                 alt="WeStudy"
                 src="/library/img/westudy_logo.png"
            />
          </router-link>
        </v-col>
        <v-col cols="12" md="2">
          <router-link
              v-for="(item , i) in navigationList" :key="`navigation_${i}`"
              :style="`color : ${wsACCENT}`"
              class=" noUnderline linkHover "
              :to="item.route">
            <h4 class="mr-3"  style="font-size: 16px; font-weight: 400; line-height: 30px">{{ $t(item.text) }}</h4>
          </router-link>
        </v-col>
        <v-col cols="12" md="2">
          <h4 class="mr-3" :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">{{ $t('Address') }}:</h4>
          <h4 class="mr-3" :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">{{ $t('homepage.address') }}🇺🇦</h4>
        </v-col>
        <v-col cols="12" md="2">
          <h4 class="mr-3" :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">{{ $t('Phone') }}:</h4>
          <h4 class="mr-3" :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">+38(063)964 69 04</h4>
          <h4 class="mr-3" :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">+38(063)159 63 36</h4>
          <h4 class="mr-3 mt-3" :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">Email:</h4>
          <a href="mailto:info@westudy.ua" class="noUnderline">
            <h4 class="mr-3 " :style="`color : ${wsACCENT}`" style="font-size: 16px; font-weight: 400; line-height: 30px">support@westudy.ua</h4>
          </a>

        </v-col>
        <v-col cols="12" md="4" :class="[{'pl-16' : !SM}]">
          <h4 class="mr-3" :style="`color : ${wsACCENT}`"  style="font-size: 13px;line-height: 150% !important ">{{ $t('homepage.short_info') }}</h4>
          <h4 class="mr-3 mt-7" :style="`color : ${wsDARKLIGHT}`"  style="font-size: 13px;line-height: 150% !important">© {{( new Date()).getFullYear() }} - {{ $t('homepage.requisites') }}</h4>

        </v-col>
      </v-row>

    </page-section>
    <v-divider :style="`border-color : ${wsBACKGROUND}`" />
    <page-section background-color="white"  no-padding >
      <div :class="[{'d-flex' : !SM}]" class="justify-space-between align-center py-4" >
        <div :class="[{'d-flex' : !SM}]">
          <router-link
              v-for="(item , i) in documentsList" :key="`documents_${i}`"
              :style="`color : ${wsACCENT}`"
              class=" noUnderline linkHover "
              :to="item.route">
            <h4  :class="[{'text-center' : SM} , {'mr-4' : !SM}]"  style="font-size: 13px">{{ $t(item.text) }}</h4>
          </router-link>
        </div>
        <div :class="SM ? 'd-flex justify-center' : null">
          <home-socials :class="[{'mt-3' : SM},{'pb-12' : SM}]" :color="wsATTENTION" />
        </div>

      </div>
    </page-section>
  </div>
</v-sheet>


</template>

<script>
import homeSocials from "@/components/pages/homepage_westudy/UI/homeSocials";
export default {
  name: "homeFooter",
  components : {
    homeSocials
  },

  computed : {
    navigationList() {
      return [
        { text : 'DocumentationPage' , route : this.localeLink('documentation/westudy')  },
        { text : 'Pricing'  , route : this.localeLink('pricing')},
        { text : 'CheckCertificate'          , route : this.localeLink('certificate/check')  },
        { text : 'Blog'        , route : this.localeLink('news')  },
      ]
    },
    documentsList() {
      return [
        { text : 'TermsAndConditions' , route : this.localeLink('about/terms')  },
        { text : 'ConfidentialRules'  , route : this.localeLink('about/confidential')},
        { text : 'HomeOffer'          , route : this.localeLink('about/offer')  },
        { text : 'RefundRules'        , route : this.localeLink('about/refund')  },
      ]
    }
  }
}
</script>

<style scoped>

</style>